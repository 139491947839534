import { useEffect, useState } from "react";
import { ReactComponent as CrossSvg } from "../../assets/cross.svg";
import Text from "../../components/Text";
import { useModal } from "../../context/ModalContext";
import Button from "../../components/Button";
import { DayPicker } from "react-day-picker";
import "../../styles/day-picker.css";

interface ScheduleModalProps {
  onSchedulePost: (date: Date) => void;
}

const currentDate = new Date(
  new Date().getFullYear(),
  new Date().getMonth(),
  new Date().getDate()
);

const lastDate = new Date(currentDate.getTime() + 2147483647);

const ScheduleModal: React.FC<ScheduleModalProps> = ({
  onSchedulePost,
}: ScheduleModalProps) => {
  const getTodayAtMidnight = (): Date => {
    const now = new Date();
    return new Date(now.getFullYear(), now.getMonth(), now.getDate());
  };

  const { closeModal } = useModal();

  const [date, setDate] = useState<Date>(getTodayAtMidnight());
  const [selecting, setSelecting] = useState<boolean>(false);
  const [hours, setHours] = useState(
    new Date().getHours().toString().padStart(2, "0")
  );
  const [minutes, setMinutes] = useState(
    new Date().getMinutes().toString().padStart(2, "0")
  );

  const fromDateToString = (): string => {
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const fromTimeToString = (): string => {
    return `${hours}:${minutes}`;
  };

  // Handle changes to the hours input
  const handleHoursChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value.replace(/[^0-9]/g, ""); // Remove non-digit characters
    if (value.length > 2) {
      value = value.slice(0, 2); // Limit to 2 characters
    }
    if (value === "") {
      setHours("");
      return;
    }
    let num = parseInt(value, 10);
    // Clamp value between 0 and 24
    if (num < 0) {
      num = 0;
      setHours("0".padStart(value.length, "0"));
    } else if (num > 24) {
      num = 24;
      setHours("24");
    } else {
      setHours(value);
    }
  };

  // Handle changes to the minutes input
  const handleMinutesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value.replace(/[^0-9]/g, ""); // Remove non-digit characters
    if (value.length > 2) {
      value = value.slice(0, 2); // Limit to 2 characters
    }
    if (value === "") {
      setMinutes("");
      return;
    }
    let num = parseInt(value, 10);
    // Clamp value between 0 and 59
    if (num < 0) {
      num = 0;
      setMinutes("0".padStart(value.length, "0"));
    } else if (num > 59) {
      num = 59;
      setMinutes("59");
    } else {
      setMinutes(value);
    }
  };

  // Adjust hours on blur if out of range
  const handleHoursBlur = () => {
    if (hours === "") return;
    let num = parseInt(hours, 10);
    if (num < 0) num = 0;
    if (num > 24) num = 24;
    setHours(num.toString().padStart(2, "0"));
  };

  // Adjust minutes on blur if out of range
  const handleMinutesBlur = () => {
    if (minutes === "") return setMinutes("00");
    let num = parseInt(minutes, 10);
    if (num < 0) num = 0;
    if (num > 59) num = 59;
    setMinutes(num.toString().padStart(2, "0"));
  };

  useEffect(() => {
    const now = new Date();
    const delay = 60000 - (now.getSeconds() * 1000 + now.getMilliseconds());

    const timeout = setTimeout(() => {
      const now = new Date();
      if (now.getTime() > date.getTime()) {
        setDate(getTodayAtMidnight());
      }
      setDate(new Date(now.getFullYear(), now.getMonth(), now.getDate()));
      setHours(now.getHours().toString().padStart(2, "0"));
      setMinutes(now.getMinutes().toString().padStart(2, "0"));
      const interval = setInterval(() => {
        const now = new Date();
        if (now.getTime() > date.getTime()) {
          setDate(getTodayAtMidnight());
        }
        setDate(new Date(now.getFullYear(), now.getMonth(), now.getDate()));
        setHours(now.getHours().toString().padStart(2, "0"));
        setMinutes(now.getMinutes().toString().padStart(2, "0"));
      }, 60000);

      // Clear the initial timeout interval on component unmount
      return () => clearInterval(interval);
    }, delay);

    return () => clearInterval(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="relative w-[600px] bg-white-default rounded-lg px-4 py-6 gap-y-[19px] flex flex-col">
      <CrossSvg
        onClick={closeModal}
        className="absolute top-4 right-6 cursor-pointer self-end fill-grey-default hover:bg-grey-default hover:bg-opacity-20 rounded-md transition-all duration-300"
      />
      <div>
        <Text className="text-black-default font-semibold text-2xl mb-2">
          Schedule a Post
        </Text>
        <Text className="text-grey-default font-medium text-base">
          Select sending Date and Time
        </Text>
      </div>
      <div className="flex h-fit w-full gap-2">
        <div className="w-full flex flex-col items-center">
          <button
            className={
              " text-xs h-[30px] w-full rounded-md shadow-none transition-all duration-300" +
              (selecting ? " bg-yellow-default" : " bg-lightGrey-default")
            }
            onClick={() => setSelecting(true)}
            onBlur={(e) => {
              if (
                !e.currentTarget.contains(e.relatedTarget) &&
                !document
                  .querySelector(".date-picker")
                  ?.contains(e.relatedTarget)
              ) {
                setSelecting(false);
              }
            }}
          >
            {currentDate.getTime() === date.getTime()
              ? "Today"
              : fromDateToString()}
          </button>
          {selecting && (
            <DayPicker
              className="date-picker absolute" // absolute bg-white-default z-10 -bottom-40 rounded-lg p-4"
              mode="single"
              selected={date}
              onDayClick={(day) => {
                setDate(day);
                setSelecting(false);
              }}
              endMonth={lastDate}
              defaultMonth={date}
              startMonth={currentDate}
              hidden={{ before: currentDate }}
            />
          )}
        </div>
        <Text className="text-lightGrey-lightText">at</Text>
        <div className="bg-lightGrey-default text-xs w-full h-[30px] rounded-md flex justify-center items-center">
          <input
            type="text"
            value={hours}
            onChange={handleHoursChange}
            onBlur={handleHoursBlur}
            className={
              "w-12 text-end outline-none bg-transparent border-none text-xs p-0 " +
              "focus:ring-0"
            }
            placeholder="HH"
            maxLength={2}
          />
          <span className="mx-[2px]">:</span>
          <input
            type="text"
            value={minutes}
            onChange={handleMinutesChange}
            onBlur={handleMinutesBlur}
            className={
              "w-12 text-start outline-none bg-transparent border-none text-xs p-0 " +
              "focus:ring-0"
            }
            placeholder="MM"
            maxLength={2}
          />
        </div>
      </div>
      <Button
        contrast
        className="shadow-none w-full"
        onClick={() => {
          const scheduledDate = new Date(date);
          scheduledDate.setHours(parseInt(hours, 10));
          scheduledDate.setMinutes(parseInt(minutes, 10));
          onSchedulePost(scheduledDate);
          closeModal();
        }}
      >
        {"Schedule " +
          (currentDate.getTime() === date.getTime()
            ? "Today"
            : "on " + fromDateToString()) +
          " at " +
          fromTimeToString()}
      </Button>
    </div>
  );
};

export default ScheduleModal;
