// ImageUpload.tsx
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { selectPostById } from "../../store/selectors";
import { useQuery } from "@apollo/client";
import { GET_MEDIA } from "../../graphql/media";
import ImageItem from "../../components/ImageItem";
import { updateLastPost } from "../../store/postSlice";
import { ReactComponent as AttachSVG } from "../../assets/attach.svg";
import Text from "../../components/Text";

interface ImageUploadProps {
  onUploadImages: (images: number) => void;
}

interface ImageFile {
  id?: string;
  file?: File;
  previewUrl: string;
  uploadId: string;
}

// TODO: Check what happens on Publishing the post => most probably will need to remove all the images from the state

const ImageUpload: React.FC<ImageUploadProps> = ({ onUploadImages }) => {
  const currentPack = useSelector((state: RootState) => state.pack.activePack);
  const { post_id: postId } = useSelector((state: RootState) =>
    selectPostById(state, currentPack.id)
  );

  const dispatch = useDispatch();

  const [images, setImages] = useState<ImageFile[]>([]);

  const fileInputRef = React.useRef<HTMLInputElement>(null);

  const { data, loading, error } = useQuery(GET_MEDIA, {
    variables: { postId },
  });

  const handleFileButtonClick = () => {
    fileInputRef.current?.click();
  };

  const handleFilesSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if ((files?.length ?? 0 + images.length) > 10) {
      alert("You can upload a maximum of 10 images");
      return;
    }

    const didNotUploadFiles: string[] = [];

    if (files) {
      const newImages = Array.from(files)
        .filter((file) => {
          if (file.size > 10 * 1024 * 1024) {
            didNotUploadFiles.push(file.name);
            return false;
          }
          return true;
        })
        .map((file) => ({
          file,
          previewUrl: URL.createObjectURL(file),
          uploadId: `ui-${Date.now()}-${Math.random()}`,
        }));
      if (didNotUploadFiles.length > 0) {
        alert(
          `The following files are larger than 10MB and cannot be uploaded: ${didNotUploadFiles.join(
            ", "
          )}`
        );
      }
      event.target.value = "";
      setImages((prevImages) => [...prevImages, ...newImages]);
    }
  };

  const handleDelete = (uploadId: string) => {
    setImages((prevImages) =>
      prevImages.filter((img) => img.uploadId !== uploadId)
    );
  };

  useEffect(() => {
    dispatch(
      updateLastPost({
        smm_pack_id: currentPack.id,
        mediaType: images.length === 0 ? "none" : "images",
      })
    );
    onUploadImages(images.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [images]);

  useEffect(() => {
    if (data && !loading && !error) {
      const media = data.mediaByPostId;
      const newImages = media.map((m: any) => ({
        file: undefined,
        previewUrl: m.url,
        id: m.id,
        uploadId: m.id, // Use the id as uploadId for existing images
      }));
      setImages(newImages);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loading, error]);

  return (
    <div className="flex flex-col flex-wrap gap-4">
      <div className="flex">
        <button
          onClick={handleFileButtonClick}
          className="w-fit group"
          disabled={images.length >= 10}
        >
          <AttachSVG className="w-6 h-6 mr-2 group-disabled:fill-grey-default fill-lightGrey-default" />
        </button>
        <Text className="text-xs text-white-default">
          {`You have uploaded ${images.length ?? 0}/10 files`}
        </Text>
        <input
          type="file"
          accept="image/*"
          multiple
          ref={fileInputRef}
          onChange={handleFilesSelected}
          className="hidden"
        />
      </div>
      {images.length !== 0 && (
        <div className="flex flex-row gap-1.5">
          {images.map((image) => (
            <ImageItem
              key={image.uploadId}
              image={image}
              postId={postId!}
              onDelete={handleDelete}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default ImageUpload;
