// ChipInput.tsx
import { ReactComponent as Cross } from "../assets/cross.svg";
import Text from "./Text";

interface ChipInputProps {
  text: string;
  icon: React.ReactNode;
  id: number;
  onDelete: (id: number) => void;
}

const ChipInput: React.FC<ChipInputProps> = ({ text, icon, id, onDelete }) => {
  return (
    <div className="flex justify-center items-center w-fit px-2 py-1 bg-lightGrey-default border border-grey-default rounded-md">
      {icon && <span className="w-fit h-fit mr-1 text-black-default">{icon}</span>}
      <Text className="m-0 mr-3 text-black-default">{text}</Text>
      <Cross
        className="cursor-pointer fill-grey-default"
        onClick={() => onDelete(id)}
      />
    </div>
  );
};

export default ChipInput;
