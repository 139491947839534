import { gql } from "@apollo/client";

export const GENERATE_POST = gql`
  mutation GeneratePost($generatePostInput: GeneratePostInput!) {
    generatePost(generatePostInput: $generatePostInput) {
      id
      text
    }
  }
`;

export const CREATE_POST = gql`
  mutation CreatePost($createPostInput: CreatePostInput!) {
    createPost(createPostInput: $createPostInput) {
      id
    }
  }
`;

export const GET_LAST_CREATED_POST = gql`
  query GetLastCreatedPost($smm_pack_id: String!) {
    lastCreatedPost(smm_pack_id: $smm_pack_id) {
      id
      text
    }
  }
`;

export const UPDATE_POST = gql`
  mutation UpdatePost($id: String!, $updatePostInput: UpdatePostInput!) {
    updatePost(id: $id, updatePostInput: $updatePostInput)
  }
`;

export const PUBLISH_POST = gql`
  mutation PublishPost($publishPostInput: PublishPostInput!) {
    publishPost(publishPostInput: $publishPostInput)
  }
`;