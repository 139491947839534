import React, { useEffect, useState } from "react";
import { ReactComponent as RemoveSVG } from "../assets/remove.svg";

interface SourceProps {
  initialLink: string;
  onRemove: () => void;
  onUpdate: (newLink: string) => void;
  isEditing?: boolean;
}

const Source: React.FC<SourceProps> = ({
  initialLink,
  onRemove,
  onUpdate,
  isEditing,
}) => {
  const [editing, setEditing] = useState(false);
  const [link, setLink] = useState(initialLink);

  useEffect(() => {
    if (isEditing) {
      setEditing(true);
    }
  }, [isEditing]);

  const handleDoubleClick = () => {
    setEditing(true);
  };

  const handleBlur = () => {
    setEditing(false);
    onUpdate(link);
  };

  return (
    <div
      className={
        "w-full min-w-[220px] h-10 " +
        "text-white-default bg-transparent border border-lightGrey-default border-opacity-30 rounded-lg " +
        "flex justify-between items-center py-[11px] px-3 box-border " +
        "transition-all ease-linear duration-300 " +
        "cursor-text " +
        "focus-within:border-white-default hover:border-[#EFEFEF99] hover:focus-within:border-white-default"
      }
      onClick={handleDoubleClick}
    >
      {editing ? (
        <input
          type="text"
          value={link}
          onChange={(e) => setLink(e.target.value)}
          onBlur={handleBlur}
          autoFocus
          placeholder="Enter a link"
          className="bg-transparent focus:outline-none focus:border-none focus:ring-0 text-white-default w-4/5 text-sm p-0 overflow-hidden inline-block whitespace-nowrap"
        />
      ) : link !== "" ? (
        <span className="w-4/5 overflow-hidden text-sm inline-block whitespace-nowrap">
          {link}
        </span>
      ) : (
        <span className="w-4/5 overflow-hidden text-sm inline-block whitespace-nowrap text-lightGrey-default text-opacity-30">
          Enter a link
        </span>
      )}
      <RemoveSVG
        className="w-5 h-5 cursor-pointer fill-white-default hover:bg-lightGrey-default hover:bg-opacity-30 rounded-md p-1 transition-all duration-300"
        onClick={onRemove}
      />
    </div>
  );
};

export default Source;
