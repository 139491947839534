import React, { useEffect, useState } from "react";
import Text from "../components/Text";
import { useNavigate } from "react-router-dom";
import GoogleLoginButton from "../components/GoogleLoginButton";

const quotes = [
  {
    text: "One of the main advantages is ease of use. The Telegram interface is clean, intuitive, and well-organized. This allows for easy access to the necessary functions, both for new users and experienced ones.",
    author: "Vincent Obi",
  },
  {
    text: "The best way to predict the future is to invent it.",
    author: "Alan Kay",
  },
  {
    text: "Life is 10% what happens to us and 90% how we react to it.",
    author: "Charles R. Swindoll",
  },
];

const RegistrationPage: React.FC = () => {
  const navigate = useNavigate();
  const [currentQuoteIndex, setCurrentQuoteIndex] = useState(
    Math.floor(Math.random() * 3)
  );
  const [fade, setFade] = useState(true);

  useEffect(() => {
    const accessToken = localStorage.getItem("access_token");
    if (accessToken) {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    const interval = setInterval(() => {
      setFade(false);
      setTimeout(() => {
        setCurrentQuoteIndex((prevIndex) => (prevIndex + 1) % quotes.length);
        setFade(true);
      }, 700); // Duration of fade out
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="flex h-screen">
      <div className="flex-1 flex flex-col justify-center items-center bg-yellow-default pt-[69px] pr-[124px] pb-0 pl-[83px]">
        <Text className="fixed top-5 left-5">logo</Text>
        <blockquote className={`mt-5 pl-5 border-l-4 border-black transition-opacity duration-500 ease-in-out ${fade ? "opacity-100" : "opacity-0"}`}>
          <Text className="mb-5">“{quotes[currentQuoteIndex].text}”</Text>
          <Text>{quotes[currentQuoteIndex].author}</Text>
        </blockquote>
      </div>
      <div className="w-[743px] p-[200px_160px_160px] bg-black-default flex-1 flex flex-col justify-center items-center text-white-default">
        <Text className="font-bold text-5xl mb-5">Get’s Started</Text>
        <Text className="font-medium text-lg mb-5">
          For the purpose of industry regulation, your details are required.
        </Text>
        <GoogleLoginButton />
      </div>
    </div>
  );
};

export default RegistrationPage;
