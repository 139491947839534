import React, { useEffect, useState } from "react";
import Text from "./Text";
import ImageUpload from "../pages/Home/ImageUpload";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { selectPostById } from "../store/selectors";

interface TextAreaProps
  extends React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {
  label: string;
  resizable?: boolean;
  media?: boolean;
  mediaType?: string;
}

const TextArea: React.FC<TextAreaProps> = ({
  label,
  id,
  className,
  resizable = false,
  value,
  media = false,
  mediaType,
  ...props
}) => {

  const activePack = useSelector((state: RootState) => state.pack.activePack);
  const post = useSelector((state: RootState) =>
    selectPostById(state, activePack.id)
  );
  
  // Do not remove! This will be used to toggle between images and videos in the future
  const [isImageSelected, setIsImageSelected] = useState(
    mediaType !== "videos"
  );
  const [disabled, setDisabled] = useState(false);

  const [taMargin, setTaMargin] = useState<"mb-0 " | "mb-28 " | "mb-10 ">(
    "mb-0 "
  );

  const handleToggle = () => {
    setIsImageSelected((prev) => !prev);
  };

  const textAreaRef = React.useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (!resizable) return;
    if (textAreaRef.current) {
      textAreaRef.current.style.height =
        (textAreaRef.current.scrollHeight ?? 0) + "px";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textAreaRef, value]);

  useEffect(() => {
    if (post.mediaType === "none") {
      setTaMargin("mb-10 ");
    } else if (post.mediaType === "images") {
      setTaMargin("mb-28 ");
    }
  }, [post.mediaType]);

  return (
    <div className="w-full">
      <Text className="text-white-default mb-1.5 text-xs">{label}</Text>
      <div
        className={
          "relative box-border " +
          "rounded-lg outline-none border border-lightGrey-default border-opacity-30 bg-transparent " +
          "transition-all duration-300 ease-in-out " +
          "hover:border-lightGrey-default hover:border-opacity-75 " +
          "focus-within:border-lightGrey-default focus-within:hover:border-lightGrey-default"
        }
      >
        <textarea
          ref={textAreaRef}
          onInput={() => {
            if (!resizable) return;
            if (textAreaRef.current) {
              textAreaRef.current.style.height =
                (textAreaRef.current.scrollHeight ?? 0) + "px";
            }
          }}
          className={
            "max-w-[720px] w-full h-fit min-h-[120px] max-h-[240px] " +
            "resize-none p-3 " +
            `${
              media ? taMargin : "mb-0 "
            }` +
            "border-none bg-transparent outline-none " +
            "text-white-default " +
            "placeholder:text-sm placeholder:text-grey-default " +
            "focus:ring-0 focus:outline-none focus:border-none " +
            className
          }
          id={id}
          value={value}
          {...props}
        ></textarea>
        {/* Button to select files */}
        {/* {media && (
          <ToggleSwitch
            isImageSelected={isImageSelected}
            onToggle={handleToggle}
            disabled={disabled}
          />
        )} */}
        {
          media && (
            // (isImageSelected ? (
            <div
              className={
                "absolute bottom-0 left-0 ml-3 mb-3 w-full h-fit flex items-start justify-start"
              }
            >
              <ImageUpload
                onUploadImages={(images) =>
                  images == 0 ? setDisabled(false) : setDisabled(true)
                }
              />
            </div>
          )
          // ) : (
          //   <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-opacity-50 bg-black">
          //     <VideoUpload
          //       onUploadVideos={(videos) =>
          //         videos == 0 ? setDisabled(false) : setDisabled(true)
          //       }
          //     />
          //   </div>
          // ))
        }
      </div>
    </div>
  );
};

export default TextArea;
