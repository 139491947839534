import { User } from "../../store/userSlice";

export const startConversation = (currentUser: User) => {
    if (!currentUser.telegramId)
      return alert("Please connect your Telegram account first");
    fetch(`${process.env.REACT_APP_BACK_URL}/telegram-bot/start-conversation`, {
      method: "POST",
      body: JSON.stringify({ telegram_id: currentUser.telegramId }),
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${currentUser.accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.message === "Conversation started")
          window.open(process.env.REACT_APP_TELEGRAM_BOT_URL);
        else
          alert(
            "An error occurred while starting the conversation. Please try again later."
          );
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };