import { useSelector } from "react-redux";
import { RootState } from "../../store";
import {
  DocumentNode,
  FetchResult,
  useMutation,
  useQuery,
} from "@apollo/client";
import { ReactComponent as RefreshSVG } from "../../assets/refresh.svg";
import { ReactComponent as PlusSVG } from "../../assets/plus.svg";
import { useEffect, useState } from "react";
import Text from "../../components/Text";
import { ReactComponent as TickIcon } from "../../assets/tick.svg";
import { ReactComponent as CrossIcon } from "../../assets/cross.svg";
import { IPostTo, SM } from "../../store/postSlice";
import { ReactComponent as TrashIcon } from "../../assets/trash.svg";
import { toast } from "react-toastify";
import Button from "../../components/Button";

interface ChannelsProps {
  onNewPostToChange: (
    sm: "telegram" | "linkedin",
    newPostTo: IPostTo[]
  ) => void;
  onPostToDelete: (id: string) => void; // Delete channel from database
  defaultPostTo: IPostTo[];
  onAddChannel: () => void;
  query: DocumentNode;
  deleteChannelMutation: DocumentNode;
  sm: SM;
  ableToAddChannel: boolean;
}

const Channels: React.FC<ChannelsProps> = ({
  onNewPostToChange,
  onPostToDelete,
  defaultPostTo,
  onAddChannel,
  query,
  deleteChannelMutation,
  sm,
  ableToAddChannel,
}: ChannelsProps) => {
  const activePack = useSelector((state: RootState) => state.pack.activePack);

  const [newPostTo, setNewPostTo] = useState<IPostTo[]>(defaultPostTo);
  const [channelToRemove, setChannelToRemove] = useState<string | null>(null);
  const [refreshEffect, setRefreshEffect] = useState<boolean>(false);
  const [addEffect, setAddEffect] = useState<boolean>(false);

  const toggleRefreshEffect = () => setRefreshEffect(!refreshEffect);
  const toggleAddEffect = () => {
    setAddEffect(!addEffect);
  };

  const capitalizedSM = sm.charAt(0).toUpperCase() + sm.slice(1);

  // TODO understand how to specify the data type that is being returned from useQuery
  const { loading, error, data, refetch } = useQuery(query, {
    variables: { smm_pack_id: activePack.id },
  });

  const [removeChannel] = useMutation(deleteChannelMutation);

  const handleRemoveChannel = (index: string | null) =>
    setChannelToRemove(index);

  /*
    Sends a graphQL Mutation to confirm channel removal
  */
  const confirmChannelRemove = async (id: string) => {
    removeChannel({ variables: { id } })
      .then((removedChannels: FetchResult<any>) => {
        if (removedChannels.data[`remove${capitalizedSM}Channel`] === 1) {
          setChannelToRemove(null);
          onPostToDelete(id);
          toast.success(`Successfully remove channel`);
        } else {
          toast.error(
            "There was an error removing the channel. Please, try again later"
          );
        }
        refetch();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleSet = (e: any) => {
    const channelId = e.target.value;
    const existingChannelIndex = newPostTo.findIndex(
      (value) => value.id === channelId
    );
    const updatedPostTo = [...newPostTo];
    if (existingChannelIndex !== -1) {
      // Remove channel from newPostTo
      updatedPostTo.splice(existingChannelIndex, 1);
    } else {
      // Add channel to newPostTo
      const channel = data[`${sm}ChannelsBySmmPack`].find(
        (channel: { id: number }) => channel.id.toString() === channelId
      );
      if (channel) {
        const channelToAdd = {
          sm,
          id: channel.id.toString(),
          name: channel.name.toString(),
        } as IPostTo;
        updatedPostTo.push(channelToAdd);
      }
    }
    setNewPostTo(updatedPostTo);
  };

  useEffect(() => {
    onNewPostToChange(sm, newPostTo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newPostTo]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error</div>;

  return (
    <div className="w-full">
      <div className="flex justify-between mb-3 text-white-default ">
        <Text className="text-xs font-semibold">
          {sm.charAt(0).toUpperCase() + sm.slice(1)}
        </Text>
        <div className="flex gap-2 mr-1">
          <RefreshSVG
            className={
              "cursor-pointer fill-yellow-default " +
              "hover:-rotate-[30deg] " +
              `${refreshEffect && "animate-spin-cw "}` +
              "transition-all duration-300 ease-in-out"
            }
            onClick={() => {
              refetch();
              toggleRefreshEffect();
            }}
            onAnimationEnd={toggleRefreshEffect}
          >
            Refresh
          </RefreshSVG>
          <PlusSVG
            className={`${
              ableToAddChannel
                ? "fill-yellow-default cursor-pointer "
                : "fill-lightGrey-default cursor-not-allowed "
            } ${addEffect && "animate-enlarge-and-back "}`}
            onClick={() => {
              onAddChannel();
              toggleAddEffect();
            }}
            onAnimationEnd={toggleAddEffect}
          >
            Add Channel
          </PlusSVG>
        </div>
      </div>
      <div className="max-h-[300px] overflow-y-scroll flex flex-col">
        {data[`${sm}ChannelsBySmmPack`].length > 0 ? (
          data[`${sm}ChannelsBySmmPack`].map(
            (channel: { id: string; name: string; username: string }) => (
              <div className="w-full h-max mb-3" key={channel.id}>
                <div
                  key={channel.id.toString()}
                  className="inline-flex w-full items-center"
                >
                  <label
                    className="flex items-center cursor-pointer relative focus-visible:outline-none"
                    htmlFor={channel.id.toString()}
                  >
                    <input
                      type="checkbox"
                      id={channel.id.toString()}
                      value={channel.id.toString()}
                      name={channel.id.toString()}
                      checked={
                        newPostTo.filter(
                          (value) =>
                            value.id.toString() === channel.id.toString()
                        ).length > 0
                      }
                      onChange={handleSet}
                      className={
                        "peer " +
                        "h-6 w-6 " +
                        "bg-white-default " +
                        "transition-all duration-300 ease-in-out " +
                        "checked:bg-yellow-default " +
                        "focus:border-none focus:shadow-none focus:ring-0 focus:ring-offset-0 " +
                        "hover:bg-yellow-default " +
                        "checked:hover:bg-yellow-default " +
                        "checked:focus:bg-yellow-default " +
                        "hover:focus:bg-yellow-default " +
                        "rounded border-none " +
                        "cursor-pointer"
                      }
                    />
                    {newPostTo.filter(
                      (value) => value.id.toString() === channel.id.toString()
                    ).length > 0 ? (
                      <span className="absolute text-white opacity-0 peer-checked:opacity-100 peer-checked:bg-yellow-default top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 hover:bg-yellow-default">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          stroke="currentColor"
                          strokeWidth="1"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                      </span>
                    ) : (
                      <></>
                    )}
                  </label>
                  <label
                    className="cursor-pointer ml-2 text-xs text-white-default"
                    htmlFor={channel.id.toString()}
                  >
                    {channel.name}
                  </label>
                  <div className="flex-grow" />
                  <TrashIcon
                    className="cursor-pointer hover:bg-white-default fill-white-default hover:bg-opacity-20 w-5 h-max p-1 rounded transition-all duration-300 ease-in-out"
                    onClick={() => handleRemoveChannel(channel.id)}
                  />
                </div>
                {/* Delete Channel box */}
                {channel.id === channelToRemove ? (
                  <div className={"bg-white-default rounded-lg px-4 py-6"}>
                    <Text>Are you sure you want to delete this channel?</Text>
                    <div className="flex gap-2">
                      <Button
                        onClick={() => handleRemoveChannel(null)}
                        icon={<CrossIcon />}
                        size="small"
                      >
                        <Text className="font-medium text-xs">No</Text>
                      </Button>
                      <Button
                        contrast
                        type="button"
                        size="small"
                        onClick={() => confirmChannelRemove(channel.id)}
                        icon={<TickIcon />}
                        className="gap-2"
                      >
                        <Text className="font-medium text-xs">
                          Yes, delete it
                        </Text>
                      </Button>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            )
          )
        ) : (
          <Text
            className="cursor-pointer text-xs text-white-default"
            onClick={onAddChannel}
          >
            Add a new channel
          </Text>
        )}
      </div>
    </div>
  );
};

export default Channels;
