import { useEffect, useRef, useState } from "react";

interface TextInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  placeholder: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  defaultValue?: string;
}

const TextInput: React.FC<TextInputProps> = ({
  onChange,
  placeholder,
  defaultValue,
  ...props
}) => {
  const ref = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState<string>("");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(e);
  };

  const handleClickInside = () => {
    if (ref.current) {
      ref.current.focus();
    }
  };

  useEffect(() => {
    setValue(defaultValue ?? "");
  }, [defaultValue]);

  return (
    <div className="w-full">
      <label
        className="block mb-1.5 text-xs font-semibold leading-none text-white-default"
        htmlFor={props.id}
      >
        {props.label}
      </label>
      <div
        className="flex flex-col w-full max-w-[420px] p-2.5 border-[1.5px] border-[rgba(239,239,239,0.2)] rounded-lg bg-transparent text-lightGrey-default text-sm font-normal leading-[17px] transition-colors duration-300 outline-none hover:border-lightGrey-default hover:border-opacity-60 focus-within:border-lightGrey-default hover:focus-within:border-lightGrey-default"
        onClick={handleClickInside}
      >
        <input
          ref={ref}
          type="text"
          value={value}
          onChange={handleChange}
          placeholder={placeholder}
          className="w-full bg-transparent border-none text-lightGrey-default text-sm font-normal leading-[17px] outline-none placeholder-lightGrey-default placeholder-opacity-60 focus:shadow-none focus:outline-none focus:border-0 focus:ring-0"
          {...props}
        />
      </div>
    </div>
  );
};

export default TextInput;
