import React, { useEffect, useState } from "react";
import SideBar from "./SideBar";
import { useNavigate } from "react-router-dom";
import TextArea from "../../components/TextArea";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import Text from "../../components/Text";
import Button from "../../components/Button";
import { ReactComponent as MagicSVG } from "../../assets/magic.svg";
import { ReactComponent as PublishSVG } from "../../assets/publish.svg";
import { useMutation } from "@apollo/client";
import {
  CREATE_POST,
  GENERATE_POST,
  PUBLISH_POST,
  UPDATE_POST,
} from "../../graphql/post";
import {
  Post,
  SettingsState,
  defaultPost,
  removeAllPosts,
  setLastPost,
} from "../../store/postSlice";
import { selectPostById } from "../../store/selectors";
import Settings from "./Settings";
import { Flip, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ReactComponent as SuccessSVG } from "../../assets/success.svg";
import { clearUserData } from "../../store/userSlice";
import { removeActivePack } from "../../store/packSlice";
import { ReactComponent as DelaySVG } from "../../assets/delay.svg";
import { useModal } from "../../context/ModalContext";
import ScheduleModal from "./ScheduleModal";

const Home: React.FC = () => {
  const dispatch = useDispatch();

  // Navigate to the sign-up page if the user is not logged in
  const userState = useSelector((state: RootState) => state.user.currentUser);
  const navigate = useNavigate();

  const { showCustomModal } = useModal();

  useEffect(() => {
    if (userState.accessToken === null || userState.userId === null) {
      dispatch(clearUserData());
      dispatch(removeActivePack());
      dispatch(removeAllPosts());
      navigate("/sign-up");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  const [generating, setGenerating] = useState(false);

  const activePack = useSelector((state: RootState) => state.pack.activePack);
  const post = useSelector((state: RootState) =>
    selectPostById(state, activePack.id)
  );
  const [lastPostState, setLastPostState] = useState<Post>(
    useSelector((state: RootState) => selectPostById(state, activePack.id))
  );

  const [generatePost, { data }] = useMutation(GENERATE_POST);
  const [createPost] = useMutation(CREATE_POST);
  const [publishPostMutation, { data: publishPostData }] =
    useMutation(PUBLISH_POST);
  const [updatePost] = useMutation(UPDATE_POST);

  useEffect(() => {
    setLastPostState(post ?? defaultPost);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePack.id]);

  useEffect(() => {
    if (lastPostState && lastPostState.smm_pack_id) {
      dispatch(setLastPost(lastPostState));
    }
    createPostWhenEmpty();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastPostState]);

  // update the last post in the store when a new post is created
  useEffect(() => {
    if (data) {
      setLastPostState({
        ...lastPostState,
        post_text: data.generatePost.text,
        post_id: data.generatePost.id,
      });
      setButtonDisability({
        ...lastPostState,
        post_text: data.generatePost.text,
        post_id: data.generatePost.id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  // on Publishing the post
  useEffect(() => {
    if (publishPostData === undefined) return;
    if (publishPostData.publishPost === "WAITING") {
      toast.info("Your post is scheduled for publishing", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Flip,
      });
      setLastPostState({
        ...defaultPost,
        smm_pack_id: activePack.id,
        settings: lastPostState.settings,
      });
      dispatch(
        setLastPost({
          ...defaultPost,
          smm_pack_id: activePack.id,
          settings: lastPostState.settings,
        })
      );
    } else if (publishPostData.publishPost === "SENT") {
      toast.success("Your post has been published successfully", {
        icon: <SuccessSVG />,
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Flip,
      });
      setLastPostState({
        ...defaultPost,
        smm_pack_id: activePack.id,
        settings: lastPostState.settings,
      });
      dispatch(
        setLastPost({
          ...defaultPost,
          smm_pack_id: activePack.id,
          settings: lastPostState.settings,
        })
      );
    } else {
      toast.error("An error occurred while publishing your post", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Flip,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publishPostData]);

  const createPostWhenEmpty = () => {
    if (
      lastPostState &&
      lastPostState.post_id === null &&
      lastPostState.smm_pack_id !== null
    ) {
      setTimeout(
        () =>
          createPost({
            variables: {
              createPostInput: {
                text: lastPostState.post_text ?? "",
                smm_pack_id: activePack.id,
              },
            },
          }).then(({ data: createPostData }) => {
            if (createPostData) {
              setLastPostState({
                ...lastPostState,
                post_id: createPostData.createPost.id,
                post_text: createPostData.createPost.text,
              });
            }
          }),
        100
      );
    }
  };

  const handleSaveSettings = (settings: SettingsState) => {
    setLastPostState({ ...lastPostState, settings });
    setButtonDisability({ ...lastPostState, settings });
  };

  const setButtonDisability = (newPostState: Post) => {
    if (newPostState.prompt === null || newPostState.prompt === "") {
      newPostState["prompt_button_disabled"] = true;
    } else {
      newPostState["prompt_button_disabled"] = false;
    }
    if (
      newPostState.post_text === null ||
      newPostState.post_text === "" ||
      Object.values(newPostState.settings?.post_to || {}).reduce(
        (sum, arr) => sum + (arr?.length || 0),
        0
      ) === 0
    ) {
      newPostState["post_text_button_disabled"] = true;
    } else {
      newPostState["post_text_button_disabled"] = false;
    }
    setLastPostState(newPostState);
  };

  const handlePromptChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newPostState = { ...lastPostState, prompt: e.target.value };
    setLastPostState({
      ...lastPostState,
      prompt: e.target.value,
    });
    setButtonDisability(newPostState);
  };

  const handleGenerateText = async () => {
    const { prompt, post_id, settings } = lastPostState;
    if (prompt === null || prompt === "") {
      alert("Please enter a prompt");
    } else {
      try {
        setGenerating(true);
        if (post_id == null) {
          const { post_to, ...filteredSettings } = settings!;
          await generatePost({
            variables: {
              generatePostInput: {
                prompt: prompt,
                type: "generate",
                smm_pack_id: activePack.id,
                settings: filteredSettings,
              },
            },
          }).then(() => setGenerating(false));
        } else {
          const { post_to, ...filteredSettings } = settings!;
          await generatePost({
            variables: {
              generatePostInput: {
                prompt: prompt,
                type: "regenerate",
                post_id: post_id,
                settings: filteredSettings,
              },
            },
          }).then(() => setGenerating(false));
        }
      } catch (err: any) {
        toast.error("An error occurred while generating your post", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Flip,
        });
        setGenerating(false);
        console.error("Error creating post:", err.message);
      }
    }
  };

  const handlePostTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setLastPostState({
      ...lastPostState,
      post_text: e.target.value,
    });
    setButtonDisability({ ...lastPostState, post_text: e.target.value });
  };

  const publishPost = async (date?: Date | undefined) => {
    let delay: string | undefined;
    if (date) {
      delay = (date.getTime() - new Date().getTime()).toString();
    }
    try {
      if (!lastPostState.post_id) {
        const { data: createPostData } = await createPost({
          variables: {
            createPostInput: {
              text: lastPostState.post_text,
              smm_pack_id: activePack.id,
            },
          },
        });

        if (createPostData) {
          setLastPostState({
            ...lastPostState,
            post_id: createPostData.createPost.id,
            post_text: createPostData.createPost.text,
          });
        }
        const combinedPostTo = Object.values(lastPostState.settings!.post_to)
          .flat()
          .map(({ name, ...rest }) => rest);
        await publishPostMutation({
          variables: {
            publishPostInput: {
              post_id: createPostData.createPost.id,
              post_to: combinedPostTo,
              schedule_date_milliseconds: delay,
            },
          },
        });
      } else {
        const combinedPostTo = Object.values(lastPostState.settings!.post_to)
          .flat()
          .map(({ name, ...rest }) => rest);
        await updatePost({
          variables: {
            id: lastPostState.post_id,
            updatePostInput: {
              text: lastPostState.post_text,
            },
          },
        });
        await publishPostMutation({
          variables: {
            publishPostInput: {
              post_id: lastPostState.post_id,
              post_to: combinedPostTo,
              schedule_date_milliseconds: delay,
            },
          },
        });
      }
    } catch (err: any) {
      console.error("Error publishing post:", err.message);
    }
  };

  return (
    <>
      <div className="flex h-screen">
        <SideBar />
        {activePack.id ? (
          <div className="flex-1 py-10 px-5 bg-[#212121] flex gap-2 content-stretch items-center box-border">
            <Settings
              onSave={handleSaveSettings}
              settings={lastPostState.settings!}
            />
            <div className="overflow-y-scroll grow-[3] h-full w-full flex flex-col items-center bg-black-default rounded-2xl py-10 px-[76px] box-border relative">
              <div className="min-w-[520px] w-fit flex flex-col items-start gap-8">
                <Text className="text-white-default font-semibold text-2xl">
                  {activePack.name}
                </Text>
                <div className="min-w-[620px] w-full max-w-[720px] flex flex-col gap-4">
                  <TextArea
                    label="Prompt text"
                    id="prompt"
                    placeholder="Prompt..."
                    onChange={handlePromptChange}
                    value={lastPostState.prompt ?? ""}
                  />
                  <Button
                    className="self-end"
                    reverse
                    icon={<MagicSVG />}
                    disabled={lastPostState.prompt_button_disabled}
                    onClick={handleGenerateText}
                  >
                    <Text>Generate Text</Text>
                  </Button>
                </div>
                <div className="max-w-[720px] flex flex-col gap-4 w-full">
                  <TextArea
                    label="Post text"
                    resizable
                    id="text"
                    placeholder="Text..."
                    onChange={handlePostTextChange}
                    value={
                      !generating
                        ? lastPostState.post_text ?? ""
                        : "Text is generating..."
                    }
                    media={lastPostState.post_id !== null}
                    mediaType={lastPostState.mediaType}
                  />
                  <div className="flex gap-4 self-end">
                    <Button
                      contrast
                      onClick={() =>
                        showCustomModal(
                          <ScheduleModal
                            onSchedulePost={(date) => {
                              publishPost(date);
                            }}
                          />
                        )
                      }
                      disabled={lastPostState.post_text_button_disabled}
                    >
                      <DelaySVG />
                    </Button>
                    <Button
                      contrast
                      icon={<PublishSVG />}
                      disabled={lastPostState.post_text_button_disabled}
                      onClick={() => publishPost()}
                    >
                      <Text>Publish</Text>
                    </Button>
                  </div>
                </div>
                <div className="flex gap-10">
                  <div>
                    <Text className="text-white-default text-xs font-semibold mb-4">
                      Telegram Channels
                    </Text>
                    {(post.settings?.post_to["telegram"] ?? []).map((channel) => {
                      return (
                        <Text
                          className="text-grey-default text-xs font-normal"
                          key={channel.id}
                        >
                          {channel.name}
                        </Text>
                      );
                    })}
                  </div>
                  <div>
                    <Text className="text-white-default text-xs font-semibold mb-4">
                      Linkedin Groups
                    </Text>
                    {(post.settings?.post_to["linkedin"] ?? []).map((channel) => {
                      return (
                        <Text
                          className="text-grey-default text-xs font-normal"
                          key={channel.id}
                        >
                          {channel.name}
                        </Text>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div>Create a new Pack</div>
        )}
      </div>
      <ToastContainer />
    </>
  );
};

export default Home;
