import React from "react";
// import "../styles/components/ChipButton.scss";
import Text from "./Text";
import { ReactComponent as PlusIcon } from "../assets/plus.svg";

interface ChipButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  label: string;
  icon?: React.ReactNode;
  onPress?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const ChipButton: React.FC<ChipButtonProps> = ({
  label,
  icon = <PlusIcon />,
  onPress,
  className,
  ...props
}) => {
  return (
    <button
      className={
        "group inline-flex items-center justify-center cursor-pointer" +
        "w-fit h-8 px-3 py-0 gap-[10px] " +
        "border-yellow-default rounded-full border-[1px] border-solid bg-transparent " +
        "hover:bg-yellow-default " +
        "transition-all duration-300 " + 
        className
      }
      onClick={onPress}
      {...props}
    >
      <Text className="text-yellow-default group-hover:text-black-default">{label}</Text>
      {icon && (
        <span className="inline-flex items-center text-yellow-default group-hover:text-black-default">
          {icon}
        </span>
      )}
    </button>
  );
};

export default ChipButton;
