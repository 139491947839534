import { useEffect, useRef, useState } from "react";
import ChipInput from "./ChipInput";

interface ChipTextInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  placeholder: string;
  defaultChips: string[] | null;
  onChipChange: (chips: string[]) => void;
  icon?: React.ReactNode;
}

const ChipTextInput: React.FC<ChipTextInputProps> = ({
  label,
  id,
  icon,
  defaultChips = [],
  onChipChange,
  placeholder,
  ...props
}: ChipTextInputProps) => {
  const ref = useRef<HTMLInputElement>(null);

  const [chips, setChips] = useState<string[]>(defaultChips!);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.includes(",") && e.target.value.length > 1) {
      setChips([...chips, e.target.value.replace(",", "").trim()]);
      onChipChange([...chips, e.target.value.replace(",", "")]);
      e.target.value = "";
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      e.key === "Backspace" &&
      chips.length > 0 &&
      e.currentTarget.value === ""
    ) {
      setChips(chips.slice(0, chips.length - 1));
      onChipChange && onChipChange(chips.slice(0, chips.length - 1));
    }
  };

  const handleChipDelete = (id: number) => {
    setChips(chips.filter((chip, index) => index !== id));
    onChipChange && onChipChange(chips.filter((chip, index) => index !== id));
  };

  const handleClickInside = () => {
    if (ref.current) {
      ref.current.focus();
    }
  };

  useEffect(() => {
    if (defaultChips) {
      setChips(defaultChips);
    }
  }, [defaultChips]);

  return (
    <div className="w-full">
      <label
        htmlFor={id}
        className="block mb-1.5 text-xs font-semibold leading-none text-white-default"
      >
        {label}
      </label>
      <div
        className={`flex flex-col w-full max-w-[420px] p-2.5 border-[1.5px] border-[rgba(239,239,239,0.2)] rounded-lg bg-transparent text-lightGrey-default text-sm font-normal leading-[17px] transition-colors duration-300 outline-none hover:border-lightGrey-default hover:border-opacity-60 focus-within:border-lightGrey-default hover:focus-within:border-lightGrey-default`}
        onClick={handleClickInside}
      >
        <div
          className={`flex flex-wrap gap-1 mr-1 max-h-[150px] overflow-y-scroll ${
            chips.length > 0 ? "mb-2" : ""
          }`}
        >
          {chips.map((chip, index) => (
            <ChipInput
              key={index}
              id={index}
              text={chip}
              icon={icon}
              onDelete={() => handleChipDelete(index)}
            />
          ))}
        </div>
        <input
          ref={ref}
          type="text"
          onChange={handleChange}
          placeholder={placeholder}
          onKeyDown={handleKeyDown}
          className="w-full bg-transparent border-none text-lightGrey-default text-sm font-normal leading-[17px] outline-none placeholder-lightGrey-default placeholder-opacity-60 focus:shadow-none focus:outline-none focus:border-0 focus:ring-0"
          {...props}
        />
      </div>
    </div>
  );
};

export default ChipTextInput;
