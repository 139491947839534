import { gql } from "@apollo/client";

export const GET_TELEGRAM_CHANNELS = gql`
  query TelegramChannelsBySmmPack($smm_pack_id: String!) {
    telegramChannelsBySmmPack(smm_pack_id: $smm_pack_id) {
      id
      name
      username
    }
  }
`;

export const REMOVE_TELEGRAM_CHANNEL = gql`
  mutation RemoveTelegramChannel($id: String!) {
    removeTelegramChannel(id: $id)
  }
`;

export const GET_LINKEDIN_CHANNELS_BY_SMM_PACK = gql`
  query LinkedinChannelsBySmmPack($smm_pack_id: String!) {
    linkedinChannelsBySmmPack(smm_pack_id: $smm_pack_id) {
      id
      name
    }
  }
`;

export const OBTAIN_ALL_LINKEDIN_CHANNELS = gql`
  query obtainChannels {
    obtainChannels {
      id
      vanity_name
      localized_name
      logo
    }
  }
`;

export const CREATE_LINKEDIN_CHANNEL = gql`
  mutation CreateLinkedinChannel(
    $createLinkedinChannelInput: CreateLinkedinChannelInput!
  ) {
    createLinkedinChannel(
      createLinkedinChannelInput: $createLinkedinChannelInput
    ) {
      id
      added
    }
  }
`;

export const REMOVE_LINKEDIN_CHANNEL = gql`
  mutation RemoveLinkedinChannel($id: String!) {
    removeLinkedinChannel(id: $id)
  }
`;
