import React, { useEffect } from "react";
import Text from "./Text";
import Button from "./Button";
import TextButton from "./TextButton";
import { ReactComponent as CrossIcon } from "../assets/cross.svg";
import { ReactComponent as BlockIcon } from "../assets/block.svg";
import { ReactComponent as TickIcon } from "../assets/tick.svg";

interface AlertModalProps {
  mainText: string;
  secondaryText: string;
  onMainButton: () => void;
  mainButtonText: string;
  secondaryButtonText: string;
  onClose: () => void;
}

const AlertModal: React.FC<AlertModalProps> = ({
  mainText,
  secondaryText,
  onMainButton,
  mainButtonText,
  secondaryButtonText,
  onClose,
}) => {
  const handleMainClick = () => {
    onMainButton();
    onClose();
  };
  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (target.closest(".modal-content") === null) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [onClose]);

  return (
    <div className="fixed top-0 left-0 w-screen h-screen bg-opacity-50 bg-pureBlack flex justify-center items-center cursor-auto">
      <form id="alert-modal">
        <div className="modal-content box-border w-[600px] gap-8 rounded-2xl bg-lightGrey-default p-[30px]">
          <div className="flex flex-col items-start gap-3 mb-8">
            <CrossIcon
              onClick={onClose}
              className="cursor-pointer self-end fill-grey-default hover:bg-grey-default hover:bg-opacity-20 rounded-md transition-all duration-300"
            />
            <Text className="font-semibold text-2xl">{mainText}</Text>
            <Text className="text-base text-lightGrey-lightText">
              {secondaryText}
            </Text>
          </div>
          <div className="h-[1px] bg-[#D8DFE9]"></div>
          <div className="mt-3 flex content-stretch gap-6">
            <TextButton
              onClick={onClose}
              inverse
              icon={<BlockIcon />}
              type="button"
              className="text-[14px] "
            >
              {secondaryButtonText}
            </TextButton>
            <Button
              type="submit"
              form="alert-modal"
              contrast
              className="shadow-none flex-[3] text-[14px] "
              onClick={handleMainClick}
              icon={<TickIcon />}
            >
              <Text className="font-normal">{mainButtonText}</Text>
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AlertModal;
