import React, { ReactElement, ReactNode } from "react";

type ButtonSize = "small" | "medium" | "big";

interface ButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  icon?: ReactNode;
  iconColor?: string;
  size?: ButtonSize;
  children: React.ReactNode;
  contrast?: boolean;
  disabled?: boolean;
  reverse?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  children,
  icon,
  iconColor,
  size = "medium",
  contrast = false,
  reverse = false,
  disabled,
  className,
  onClick,
  ...props
}) => {
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!disabled && onClick) {
      onClick(event);
    }
    event.currentTarget.blur();
  };

  const sizeClasses = {
    big: "h-[50px] px-6 py-3",
    medium: "h-[40px] px-4 py-2.5",
    small: "h-[33px] px-3 py-2",
  };

  const baseStyles =
    "relative flex items-center justify-center gap-[10px] border border-transparent-default " +
    "text-base w-fit text-black-default transition ease-in-out duration-300 " +
    "bg-lightGrey-default rounded-md cursor-pointer " +
    "focus:ring-0 focus:outline-none focus-visible:ring-2 focus-visible:ring-black/20 " +
    "disabled:bg-transparent disabled:cursor-not-allowed disabled:pointer-events-none disabled:text-grey-default disabled:shadow-none disabled:border-grey-default";

  const contrastStyles =
    "bg-yellow-default text-black shadow-md border-transparent-default " +
    "disabled:bg-transparent disabled:border-grey-default";

  const iconFinal = (): ReactElement => (
    <span className="inline-flex">
      {React.cloneElement(icon as React.ReactElement, {
        className: "h-4 w-4 " + (iconColor ?? "fill-current stroke-current"),
      })}
    </span>
  );
  return (
    <button
      className={`group ${baseStyles} ${sizeClasses[size]} ${
        contrast ? contrastStyles : ""
      } ${className ? className : ""}`}
      onClick={handleClick}
      disabled={disabled}
      {...props}
    >
      <div
        className={
          "absolute top-0 left-0 w-full h-full rounded-[5px] bg-transparent transition-all duration-300 " +
          "group-hover:bg-black-pure group-hover:bg-opacity-20 group-hover:border-black-pure group-hover:border-opacity-20 " +
          "group-focus-within:bg-black-pure group-focus-within:bg-opacity-40 group-focus-within:border-black-pure group-focus:border-opacity-20"
        }
      ></div>
      {reverse && icon && iconFinal()}

      {children}

      {!reverse && icon && iconFinal()}
    </button>
  );
};

export default Button;
