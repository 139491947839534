import { gql } from "@apollo/client";

export const GET_USERS_SMM_PACK_RELATIONSHIPS = gql`
  query GetUsersSmmPackRelationships($relations: Boolean!) {
    usersSmmPackRelationships(relations: $relations) {
      smm_pack {
        id
        name
        language
        system
      }
    }
  }
`;

export const REMOVE_SMM_PACK = gql`
  mutation removeSmmPack($id: String!) {
    removeSmmPack(id: $id)
  }
`;

export const CREATE_SMM_PACK = gql`
  mutation CreateSmmPack($createSmmPackInput: CreateSmmPackInput!) {
    createSmmPack(createSmmPackInput: $createSmmPackInput) {
      id
      name
      language
      system
    }
  }
`;

export const UPDATE_SMM_PACK = gql`
  mutation UpdateSmmPack(
    $pack_id: String!
    $updateSmmPackInput: UpdateSmmPackInput!
  ) {
    updateSmmPack(pack_id: $pack_id, updateSmmPackInput: $updateSmmPackInput)
  }
`;