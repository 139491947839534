import React from "react";
import { ReactComponent as RefreshIcon } from "../assets/refresh.svg";
import Text from "./Text";

interface TextButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  children: React.ReactNode;
  icon?: React.ReactNode;
  inverse?: boolean;
  disabled?: boolean;
}

const TextButton: React.FC<TextButtonProps> = ({
  onClick,
  children,
  icon = <RefreshIcon />,
  inverse = false,
  disabled = false,
  className,
  ...props
}) => {
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (onClick) {
      onClick(event);
    }
    event.currentTarget.blur();
  };

  const iconStyles = "h-5 w-5 fill-black-default transition-all duration-300";

  const btnStyle = "group flex items-center justify-center bg-transparent hover:bg-black-default hover:bg-opacity-[0.05] p-2 rounded-md border-none text-black-default cursor-pointer text-base p-0 text-left transition-all duration-300 gap-[5px] ";

  return (
    <button
      className={btnStyle + className}
      onClick={handleClick}
      disabled={disabled}
      {...props}
    >
      {icon && !inverse && <span className={iconStyles}>{icon}</span>}
      <Text>{children}</Text>
      {icon && inverse && <span className={iconStyles}>{icon}</span>}
    </button>
  );
};

export default TextButton;
