import React, { useState, useEffect } from 'react';

interface AsyncImageProps {
  src: string;
  alt?: string;
  placeholder?: React.ReactNode;
  className?: string;
}

const AsyncImage: React.FC<AsyncImageProps> = ({ src, alt, placeholder, className }) => {
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    const img = new Image();
    img.src = src;

    const handleLoad = () => setLoaded(true);
    const handleError = () => setLoaded(true); // You might want to handle errors differently

    img.addEventListener('load', handleLoad);
    img.addEventListener('error', handleError);

    return () => {
      img.removeEventListener('load', handleLoad);
      img.removeEventListener('error', handleError);
    };
  }, [src]);

  return (
    <>
      {loaded ? (
        <img src={src} alt={alt} className={className} />
      ) : (
        placeholder || <div className={className + "animate-pulse bg-grey-default"}>Loading...</div>
      )}
    </>
  );
};

export default AsyncImage;
