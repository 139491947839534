import { useEffect } from "react";

interface CustomModalProps {
  content: React.ReactNode;
  onClose: () => void;
}

const CustomModal: React.FC<CustomModalProps> = ({ content, onClose }) => {
  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (target.closest(".modal-content") === null) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [onClose]);

  return (
    <div className="fixed top-0 left-0 w-screen h-screen bg-opacity-50 bg-pureBlack flex justify-center items-center cursor-auto">
      <div className="modal-content">{content}</div>
    </div>
  );
};

export default CustomModal;
