import { useDispatch, useSelector } from "react-redux";
import Text from "../../components/Text";
import TextInput from "../../components/TextInput";
import { RootState } from "../../store";
import Button from "../../components/Button";
import { useEffect, useState } from "react";
import {
  IPostTo,
  SM,
  SettingsState,
  setLastPostSettings,
} from "../../store/postSlice";
import { ReactComponent as TickSvg } from "../../assets/tick.svg";
import { ReactComponent as SaveSVG } from "../../assets/save.svg";
import { ReactComponent as BlockSVG } from "../../assets/block.svg";
import Channels from "./Channels";
import ChipTextInput from "../../components/ChipTextInput";
import Sources from "./Sources";
import { startConversation } from "./telegramChannelsFunctions";
import {
  GET_LINKEDIN_CHANNELS_BY_SMM_PACK,
  GET_TELEGRAM_CHANNELS,
  REMOVE_LINKEDIN_CHANNEL,
  REMOVE_TELEGRAM_CHANNEL,
} from "../../graphql/channels";
import { useModal } from "../../context/ModalContext";
import ObtainLinkedinChannels from "./ObtainLinkedinChannels";
import _ from "lodash";

interface SettingsProps {
  settings: SettingsState;
  onSave: (settings: SettingsState) => void;
}

const Settings: React.FC<SettingsProps> = ({ onSave, settings }) => {
  const dispatch = useDispatch();
  const activePack = useSelector((state: RootState) => state.pack.activePack);
  const currentUser = useSelector((state: RootState) => state.user.currentUser);

  const [settingsState, setSettingsState] = useState<SettingsState>(settings);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);

  const { showCustomModal } = useModal();

  const handleSystemChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSettingsState({ ...settingsState, system: e.target.value });
  };

  const handleChipChange = (
    chips: string[],
    keys: "positive_keys" | "negative_keys"
  ) => {
    setSettingsState({ ...settingsState, [keys]: chips });
  };

  const handleNewPostToChange = (
    sm: "telegram" | "linkedin",
    post_to: IPostTo[]
  ) => {
    setSettingsState({
      ...settingsState,
      post_to: { ...settingsState.post_to, [sm]: post_to },
    });
  };

  const handlePostToDelete = (id: string) => {
    const filteredPostTo = Object.keys(settingsState.post_to).reduce(
      (acc, key) => {
        acc[key] = (settingsState.post_to[key as SM] as IPostTo[]).filter(
          (post: IPostTo) => post.id !== id
        );
        return acc;
      },
      {} as { [key: string]: IPostTo[] }
    );

    const temporarySettingsState = {
      ...settingsState,
      post_to: filteredPostTo,
    };

    setSettingsState(temporarySettingsState);
    dispatch(
      setLastPostSettings({
        ...temporarySettingsState,
        smm_pack_id: activePack.id!,
      })
    );
    onSave({ ...temporarySettingsState });
    setSaveButtonDisabled(true);
  };

  const handleNewSources = (sources: string[]) => {
    setSettingsState({ ...settingsState, sources });
  };
  const handleSave = () => {
    dispatch(
      setLastPostSettings({ ...settingsState, smm_pack_id: activePack.id! })
    );
    onSave({ ...settingsState });
    setSaveButtonDisabled(true);
  };

  useEffect(() => {
    setSettingsState(settings);
  }, [settings]);

  useEffect(() => {
    _.isEqual(settingsState, settings)
      ? setSaveButtonDisabled(true)
      : setSaveButtonDisabled(false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settingsState]);

  return (
    <div className="flex-1 h-full min-w-[260px] bg-black-default rounded-2xl pt-10 pr-5 pb-10 pl-5 box-border transition-all duration-300">
      <form
        id="settings"
        className="flex flex-col items-start justify-start gap-8 box-border max-h-full overflow-y-scroll"
      >
        <Text className="text-lightGrey-default text-lg font-semibold">
          Settings
        </Text>
        {/* Telegram Channels */}
        <Channels
          onNewPostToChange={(sm, post_to) =>
            handleNewPostToChange(sm, post_to)
          }
          defaultPostTo={settingsState.post_to["telegram"] ?? []}
          onPostToDelete={(id) => handlePostToDelete(id)}
          onAddChannel={() => startConversation(currentUser)}
          query={GET_TELEGRAM_CHANNELS}
          deleteChannelMutation={REMOVE_TELEGRAM_CHANNEL}
          sm="telegram"
          ableToAddChannel={currentUser.telegramId ? true : false}
        />

        {/* Linkedin Channels */}
        <Channels
          onNewPostToChange={(sm, post_to) =>
            handleNewPostToChange(sm, post_to)
          }
          defaultPostTo={settingsState.post_to["linkedin"] ?? []}
          onPostToDelete={(id) => handlePostToDelete(id)}
          onAddChannel={() => showCustomModal(<ObtainLinkedinChannels />)}
          query={GET_LINKEDIN_CHANNELS_BY_SMM_PACK}
          deleteChannelMutation={REMOVE_LINKEDIN_CHANNEL}
          sm="linkedin"
          ableToAddChannel={currentUser.linkedinId ? true : false}
        />
        <Sources
          defaultSources={settingsState.sources}
          onNewSetOfLinks={handleNewSources}
        />
        <TextInput
          label="System"
          id="system"
          placeholder="e.g. polite blogger tone"
          defaultValue={settingsState.system}
          onChange={handleSystemChange}
        />
        <ChipTextInput
          label="Positive Keys"
          id="positive_keys"
          placeholder="positive,interesting,.."
          defaultChips={settingsState.positive_keys}
          onChipChange={(chips: string[]) =>
            handleChipChange(chips, "positive_keys")
          }
          icon={<TickSvg />}
        />
        <ChipTextInput
          label="Negative Keys"
          id="negative_keys"
          placeholder="negative,boring,..."
          defaultChips={settingsState.negative_keys}
          onChipChange={(chips: string[]) =>
            handleChipChange(chips, "negative_keys")
          }
          icon={<BlockSVG />}
        />
        <Button
          disabled={saveButtonDisabled}
          icon={<SaveSVG />}
          onClick={handleSave}
          type="submit"
          form="settings"
        >
          <Text>Save</Text>
        </Button>
      </form>
    </div>
  );
};

export default Settings;
