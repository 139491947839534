import React from "react";

interface TextProps extends React.HTMLAttributes<HTMLParagraphElement> {
  children: React.ReactNode;
}

const Text: React.FC<TextProps> = ({ className, children, ...props }) => {
  return (
    <p className={`flex items-center ${className ?? ""}`} {...props}>
      {children}
    </p>
  );
};

export default Text;
