import { ReactComponent as TrashIcon } from "../assets/trash.svg";
import Text from "./Text";
import { useModal } from "../context/ModalContext";
import { useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import { UPDATE_SMM_PACK } from "../graphql/smmPack";

interface SmmPackItemProps {
  id: string;
  name: string;
  isActive: boolean;
  onSetActive: (id: string) => void;
  onUpdate: () => void;
  onRemove: (id: string) => void;
  isEditing?: boolean;
}

const SmmPackItem: React.FC<SmmPackItemProps> = ({
  id,
  name,
  isActive,
  onSetActive,
  onUpdate,
  onRemove,
  isEditing: initialIsEditing = false,
}) => {
  const [isEditing, setIsEditing] = useState(initialIsEditing);
  const [packName, setPackName] = useState(name);
  const [updateSmmPack] = useMutation(UPDATE_SMM_PACK);

  const { showAlertModal } = useModal();

  const openModal = () => {
    showAlertModal(
      "Are you sure you want to delete this pack?",
      "After deleting, all information will be lost",
      () => onRemove(id),
      "Yes, Delete it!",
      "No, return"
    );
  };

  const handleDeleteClick = () => {
    openModal();
  };

  const handleSetActive = () => {
    onSetActive(id);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsEditing(false);
    try {
      await updateSmmPack({
        variables: {
          pack_id: id,
          updateSmmPackInput: {
            name: packName,
          },
        },
      });
      onUpdate();
    } catch (err) {
      console.error("Error updating SMM pack:", err);
      alert("An error occurred while trying to update the SMM pack.");
    }
  };

  useEffect(() => {
    setPackName(name);
  }, [name]);

  return (
    <div
      className={`flex justify-between items-center py-3.5 px-3 rounded-md mb-1 cursor-pointer relative box-border transition-all ease-linear duration-50 ${isActive ? "bg-white-default text-black-default transition-all duration-300 ease-in-out" : "text-lightGrey-default"}`}
      onClick={handleSetActive}
      onDoubleClick={() => setIsEditing(!isEditing)}
    >
      {isEditing ? (
        <form onSubmit={handleSubmit} className="w-fit">
          <input
            type="text"
            value={packName}
            onChange={(e) => setPackName(e.target.value)}
            onBlur={() => setIsEditing(false)}
            className="appearance-none max-w-[130px] outline-none border-none w-fit bg-white-light py-0 px-1.5 -translate-x-1.5 rounded text-sm focus:border-0 focus:outline-none focus:shadow-none focus:ring-0"
            autoFocus
          />
        </form>
      ) : (
        <Text className="text-sm w-max">
          {name}
        </Text>
      )}
      <TrashIcon
        className="w-6 h-6 cursor-pointer fill-grey-default transition-all duration-300 ease box-border py-1 px-1.25 rounded hover:bg-red-default hover:bg-opacity-20 hover:fill-red-default"
        onClick={(e) => {
          e.stopPropagation();
          handleDeleteClick();
        }}
      />
    </div>
  );
};

export default SmmPackItem;
