import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  split,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import { createClient } from "graphql-ws";

// Create an HTTP link to the GraphQL endpoint
const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_BACK_URL}/graphql`,
});

// Middleware to add the authorization header
const authLink = setContext((_, { headers }) => {
  const token = JSON.parse(
    localStorage.getItem("current_user") ?? '{"accessToken": null}'
  ).accessToken;

  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : "",
    },
  };
});

// Create a WebSocket link:
const wsLink = new GraphQLWsLink(
  createClient({
    url: `${process.env.REACT_APP_BACK_URL}/graphql`,
    connectionParams: () => {
      const token = JSON.parse(
        localStorage.getItem("current_user") ?? '{"accessToken": null}'
      ).accessToken;

      return {
        authorization: token ? `Bearer ${token}` : "",
      };
    },
  })
);

// Split links based on operation type
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  authLink.concat(httpLink)
);

// Create the Apollo Client with the splitLink and cache
const client = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache(),
});

export default client;
