import React, { useEffect, useState } from "react";
import { ReactComponent as CrossIcon } from "../assets/cross.svg";
import { ReactComponent as RetryIcon } from "../assets/refresh.svg";
import { useMutation, useSubscription } from "@apollo/client";
import { SUBSCRIBE_TO_MEDIA_UPLOAD, DELETE_MEDIA } from "../graphql/media";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { toast } from "react-toastify";
import Text from "./Text";

interface ImageFile {
  id?: string;
  file?: File;
  previewUrl: string;
  uploadId: string;
}

interface ImageItemProps {
  image: ImageFile;
  postId: string;
  onDelete: (uploadId: string) => void;
}

const ImageItem: React.FC<ImageItemProps> = ({ image, postId, onDelete }) => {
  const currentUser = useSelector((state: RootState) => state.user.currentUser);
  const [deleteMedia] = useMutation(DELETE_MEDIA);

  const [progress, setProgress] = useState<number>(image.id ? 100 : 0);
  const [error, setError] = useState<boolean>(false);
  const [id, setId] = useState<string | undefined>(image.id);
  const [url, setUrl] = useState<string>(image.previewUrl);

  useSubscription(SUBSCRIBE_TO_MEDIA_UPLOAD, {
    variables: { uploadId: image.uploadId },
    skip: progress >= 100 || error,
    onData: ({ data }) => {
      const uploadProgress = data?.data?.uploadProgress;
      if (uploadProgress) {
        const newProgress = uploadProgress.progress;
        setProgress(newProgress);
      }
    },
  });

  const handleUpload = async () => {
    try {
      const { file, uploadId } = image;

      if (!file) return;

      // Build FormData
      const formData = new FormData();

      // Construct operations object
      const operations = {
        query: `mutation UploadMedia($postId: String!, $files: [Upload!]!, $filesSettings: [FileUploadSettingsInput!]!, $batchId: String!) {
          uploadMedia(postId: $postId, files: $files, filesSettings: $filesSettings, batchId: $batchId) {
            id
            url
            filename
            mimetype
            encoding
            created_at
            post {
              id
              status
            }
          }
        }`,
        variables: {
          postId,
          batchId: postId, // Using postId as batchId
          files: [null],
          filesSettings: [{ uploadId, fileSize: file.size }],
        },
      };

      formData.append("operations", JSON.stringify(operations));
      formData.append("map", JSON.stringify({ "0": ["variables.files.0"] }));
      formData.append("0", file);

      const response = await fetch(
        `${process.env.REACT_APP_BACK_URL}/graphql`,
        {
          method: "POST",
          headers: {
            "x-apollo-operation-name": "UploadMedia",
            "Apollo-Require-Preflight": "true",
            Authorization: `Bearer ${currentUser.accessToken}`,
          },
          body: formData,
        }
      );

      if (response.ok) {
        const result = await response.json();
        const uploadedMedia = result.data.uploadMedia[0];
        // Update the image with the returned URL
        setId(uploadedMedia.id);
        setUrl(uploadedMedia.url);
        setProgress(100);
      } else {
        console.error("Upload failed:", response.statusText);
        setError(true);
      }
    } catch (error) {
      console.error("Upload error:", error);
      setError(true);
    }
  };

  useEffect(() => {
    if (!error && progress === 0 && !id) {
      handleUpload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty dependency array to run only once on mount

  const handleDeleteClick = () => {
    if (id) {
      deleteMedia({ variables: { mediaId: id } }).then((res) => {
        if (res.data.deleteMedia) {
          onDelete(image.uploadId);
        } else {
          toast.error("Error deleting media");
          console.error("Error deleting media");
        }
      });
    } else {
      // If the upload hasn't completed or failed, just remove the image
      onDelete(image.uploadId);
    }
  };

  const handleRetry = () => {
    setError(false);
    setProgress(0);
    handleUpload();
  };

  return (
    <div
      key={image.uploadId}
      className="relative w-[54px] h-[54px] bg-gray-200 rounded-sm overflow-hidden "
    >
      <img src={url} alt="Preview" className="object-cover w-full h-full" />

      <div
        className={
          "absolute inset-0 bg-transparent " +
          "transition-all duration-300 " +
          "hover:bg-white-default hover:bg-opacity-20 " +
          "peer-hover:bg-white-default peer-hover:bg-opacity-20"
        }
        onClick={() => window.open(url, "_blank")}
      ></div>
      <CrossIcon
        onClick={handleDeleteClick}
        className={
          "peer absolute top-0.5 right-0.5 " +
          "w-3 h-3 cursor-pointer " +
          "text-white bg-black rounded-[1px] " +
          "transition-all duration-300 z-2 " +
          "hover:bg-white-default hover:bg-opacity-50"
        }
      />
      {error && (
        <div className="absolute inset-0 bg-white-default bg-opacity-80 border-red-error border-[0.25px] rounded-sm flex flex-col items-center justify-center">
          <RetryIcon
            className="text-red-error mb-0.5 cursor-pointer"
            onClick={handleRetry}
          />
          <Text className="text-red-error text-[6px]">Retry</Text>
        </div>
      )}
      {!error && progress < 100 && (
        <div className="absolute inset-0 bg-white-default bg-opacity-80 flex flex-col items-center justify-center">
          <img
            src={require("../assets/spinner.png")}
            alt="Loading"
            className="w-[10px] h-[10px] animate-spin mr-1"
          />
          <p className="text-black-default text-[6px]">
            {Math.round(progress)}%
          </p>
        </div>
      )}
    </div>
  );
};

export default ImageItem;
