import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { useMutation, useQuery } from "@apollo/client";
import { useState } from "react";
import {
  CREATE_LINKEDIN_CHANNEL,
  OBTAIN_ALL_LINKEDIN_CHANNELS,
} from "../../graphql/channels";
import Button from "../../components/Button";
import { useModal } from "../../context/ModalContext";
import AsyncImage from "../../components/AsyncImage";
import { toast } from "react-toastify";

const ObtainLinkedinChannels: React.FC = () => {
  const baseStyleForModal =
    "bg-white-default rounded-xl p-4 w-fit h-fit min-w-[480px] min-h-[120px] relative";
  const { data, loading, error } = useQuery(OBTAIN_ALL_LINKEDIN_CHANNELS);
  const [createLinkedinChannel] = useMutation(CREATE_LINKEDIN_CHANNEL);

  const [selectedChannels, setSelectedChannels] = useState<
    { id: string; name: string }[]
  >([]);
  const [savingChannels, setSavingChannels] = useState(false);

  const activePack = useSelector((state: RootState) => state.pack.activePack);

  const { closeModal } = useModal();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    const objectToAdd = { id: value, name };

    if (selectedChannels.some((channel) => channel.id === value)) {
      setSelectedChannels(
        selectedChannels.filter((channel) => channel.id !== value)
      );
    } else {
      setSelectedChannels([...selectedChannels, objectToAdd]);
    }
  };

  const handleSave = async () => {
    try {
      setSavingChannels(true);
      await createLinkedinChannel({
        variables: {
          createLinkedinChannelInput: {
            smm_pack_id: activePack.id,
            linkedin_channel_id: selectedChannels,
          },
        },
      });
      setSavingChannels(false);
      toast.success(
        "🥳Channels saved successfully. Please Refresh to see the updates."
      );
      closeModal();
    } catch (error) {
      toast.error("😣Error saving channels. Try again later.");
      console.error(error);
      setSavingChannels(false);
    }
  };

  if (loading) {
    return <div className={baseStyleForModal}>Loading...</div>;
  }
  if (error) {
    return <div className={baseStyleForModal}>Error...</div>;
  }

  return (
    <div className={baseStyleForModal}>
      {savingChannels && (
        <div className="absolute inset-0 bg-black-default bg-opacity-50 z-10">
          <div className="absolute inset-0 flex justify-center items-center">
            <div className="w-20 h-20 border-4 border-t-4 border-gray-200 rounded-full animate-spin"></div>
          </div>
        </div>
      )}
      <ul>
        {data.obtainChannels.map(
          ({
            id,
            vanity_name,
            localized_name,
            logo,
          }: {
            id: string;
            vanity_name: string;
            localized_name: string;
            logo: string;
          }) => (
            <li key={id} className="flex w-full">
              <AsyncImage
                src={logo}
                alt={localized_name}
                className="w-6 h-6 rounded-full border"
              />

              <label htmlFor={id}>{vanity_name}</label>
              <input
                className={
                  "h-6 w-6 " +
                  "bg-white-default border border-black-default " +
                  "transition-all duration-300 ease-in-out " +
                  "checked:bg-yellow-default " +
                  "focus:border-none focus:shadow-none focus:ring-0 focus:ring-offset-0 self-end" +
                  "hover:bg-yellow-default " +
                  "checked:hover:bg-yellow-default " +
                  "checked:focus:bg-yellow-default " +
                  "hover:focus:bg-yellow-default " +
                  "rounded " +
                  "cursor-pointer"
                }
                type="checkbox"
                id={id}
                name={localized_name}
                value={id}
                onChange={handleChange}
              />
            </li>
          )
        )}
      </ul>
      <Button
        contrast
        disabled={selectedChannels.length === 0}
        onClick={handleSave}
      >
        Add LinkedIn Channels
      </Button>
      <Button onClick={closeModal}>Cancel</Button>
    </div>
  );
};

export default ObtainLinkedinChannels;
