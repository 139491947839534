import { gql } from "@apollo/client";

export const GET_MEDIA = gql`
  query GetMedia($postId: String!) {
    mediaByPostId(postId: $postId) {
      id
      url
      mimetype
    }
  }
`;

export const DELETE_MEDIA = gql`
  mutation DeleteMedia($mediaId: String!) {
    deleteMedia(mediaId: $mediaId)
  }
`;

export const SUBSCRIBE_TO_MEDIA_UPLOAD = gql`
    subscription UploadProgress($uploadId: String!) {
        uploadProgress(uploadId: $uploadId) {
            uploadId
            progress
        }
    }
`;